<template>
  <layout>
    <template slot="main">
      <v-card class="mt-6">
        <crud
          :modal="tetikle"
          :gelen_id="gelen_id"
          @hideDialog="showClose()"
        ></crud>
        <HareketlerShow
          :modal="tetikle_hareketler"
          :gorev_id="gorev_id"
          @hideDialog="hideDialogShow()"
        ></HareketlerShow>
        <v-card-title>
          {{ this.degisenTitle }}
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            color="red"
            dark
            @click="
              tetikle = true;
              gelen_id = null;
            "
          >
            Yeni Görev
          </v-btn>
        </v-card-title>
        <v-tabs fixed-tabs background-color="blue" dark>
          <v-tab @click="benim_gorevlerim()"> Benim Görevlerim </v-tab>
          <v-tab @click="tum_gorevler()"> Tüm Görevlerim</v-tab>
          <v-tab @click="biten_gorevler()"> Biten Görevler </v-tab>
        </v-tabs>
        <datatables :headers="headers" :items="gorevler">
          <template v-slot:dt_actions="{ item }">
            <v-icon small class="mr-2" @click="hareketleriGoster(item.id)"
              >mdi-eye-arrow-right-outline</v-icon
            >
            <v-icon small class="mr-2" @click="updateDialog(item.id)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="silDialog(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:dt_olusturma_tarihi="{ item }">
            {{ item.olusturma_tarihi | moment("DD-MM-YYYY HH:mm") }}
          </template>
          <template v-slot:dt_aciliyet_durumu="{ item }">
            <span v-if="item.aciliyet_durumu === 'Acil'" class="red--text">
              {{ item.aciliyet_durumu }}
            </span>

            <span v-if="item.aciliyet_durumu === 'Normal'" class="black--text">
              {{ item.aciliyet_durumu }}</span
            >
          </template>
          <template v-slot:dt_son_yorumcu="{ item }">
            <span
              v-if="item.son_yorumcu !== item.atanan_kullanici"
              class="red--text"
            >
              {{ item.son_yorumcu }}
            </span>
            <v-img
              v-if="
                item.son_yorumcu !== null &&
                item.son_yorumcu !== item.atanan_kullanici
              "
              src="../../assets/warning.gif"
              width="30"
              class="ml-7"
            />
            <span v-else> {{ item.son_yorumcu }}</span>
          </template>
        </datatables>
      </v-card>
    </template>
  </layout>
</template>

<script>
import Crud from "../Gorevler/Crud.vue";
import HareketlerShow from "../Gorevler/Hareketler/Show.vue";
import Datatables from "../../components/datatables/index.vue";
import {
  bitenGorevListesi,
  gorevlerListesi,
  gorevSil,
} from "../../query/gorevler";
import Layout from "@/components/Layout";
export default {
  components: { Crud, Datatables, Layout, HareketlerShow },

  async mounted() {
    if (this.$route.params.tumGorevler !== undefined) {
      this.tumGorevler = this.$route.params.tumGorevler;
      this.degisenTitle = "Görevlerim";
    }
    this.gorevler = await gorevlerListesi(this.tumGorevler);
    if (this.tumGorevler) {
      this.degisenButton = "Görevlerim";
      this.degisenTitle = "Tüm Görevler";
    }
  },

  methods: {
    showClose() {
      this.tetikle = false;
      this.son_basilan();
    },
    hideDialogShow() {
      this.tetikle_hareketler = false;
      this.son_basilan();
    },
    async silDialog(item) {
      const result = await this.$dialog.error({
        text: "Kayıt silinsin mi?",
        title: "Dikkat",
        actions: {
          true: {
            color: "error",
            text: "Evet",
          },
          false: "Hayır",
        },
      });
      if (result) {
        const sonuc = await gorevSil(item.id);
        if (sonuc !== undefined) {
          this.son_basilan();
        }
      }
    },
    updateDialog(item) {
      console.log(item);
      this.gelen_id = item;
      this.tetikle = true;
    },
    hareketleriGoster(id) {
      this.gorev_id = id;
      this.tetikle_hareketler = true;

      // this.$router.push({
      //   name: "Hareketler",
      //   params: {
      //     id: id,
      //   },
      // });
    },
    async benim_gorevlerim() {
      this.gorevler = await gorevlerListesi(false);
      this.degisenTitle = "Görevlerim";
      this.son_basilan_tabs = "1";
    },
    async tum_gorevler() {
      this.gorevler = await gorevlerListesi(true);
      this.degisenTitle = "Tüm Görevler";
      this.son_basilan_tabs = "2";
    },
    async biten_gorevler() {
      this.gorevler = await bitenGorevListesi();
      this.degisenTitle = "Biten Görevler";
      this.son_basilan_tabs = "3";
    },
    async son_basilan() {
      if (this.son_basilan_tabs === "1") {
        this.gorevler = await gorevlerListesi(false);
      } else if (this.son_basilan_tabs === "2") {
        this.gorevler = await gorevlerListesi(true);
      } else if (this.son_basilan_tabs === "3") {
        this.gorevler = await bitenGorevListesi();
      }
    },
  },

  data() {
    return {
      degisenButton: "Tüm Görevler",
      degisenTitle: "Tüm Görevler",
      tumGorevler: true,
      gorevler: [],
      tetikle: false,
      tetikle_hareketler: false,
      gelen_id: null,
      gorev_id: null,
      son_basilan_tabs: null,
      options: {},
      headers: [
        {
          text: "Kayıt No",
          value: "kayit_no",
          filterType: "string",
        },
        {
          text: "Oluşturma Tarihi",
          value: "olusturma_tarihi",
          filterType: "date",
          hasSlot: true,
        },

        {
          text: "Firma",
          value: "unvan",
          filterType: "string",
        },

        {
          text: "Konu",
          value: "konu",
          filterType: "string",
        },
        {
          text: "Durum",
          value: "aciliyet_durumu",
          filterType: "string",
          hasSlot: true,
        },

        {
          text: "Oluşturan",
          value: "olusturan_kullanici",
          filterType: "string",
        },
        {
          text: "Atanan",
          value: "atanan_kullanici",
          filterType: "string",
        },
        {
          text: "Son Bilgi Yazarı",
          value: "son_yorumcu",
          filterType: "string",
          hasSlot: true,
        },
        {
          text: "İŞLEMLER",
          value: "actions",
          hasSlot: true,
        },
      ],
    };
  },
};
</script>
