import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const firmalarListesi = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/firmalar`,
  });
};

const firmaBilgileri = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/firma/${id}`,
  });
};

const firmaKayit = async (data) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/firma_kayit`,
    json: data,
  });
};

const firmaGuncelle = async (
  unvan,
  ad,
  soyad,
  gsm,
  vergi_dairesi,
  vergi_numarasi,
  il_id,
  ilce_id,
  adres,
  mail,
  id
) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/firma_guncelle/${id}`,
    json: {
      unvan: unvan,
      ad: ad,
      soyad: soyad,
      gsm: gsm,
      vergi_dairesi: vergi_dairesi,
      vergi_numarasi: vergi_numarasi,
      il_id: il_id,
      ilce_id: ilce_id,
      adres: adres,
      mail: mail,
    },
  });
};

const firmaSil = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/firma_sil/${id}`,
  });
};

export { firmalarListesi, firmaBilgileri, firmaKayit, firmaGuncelle, firmaSil };
