<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :prependIcon="prependIcon"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    :multiple="multiple"
    @change="onChange"
    outlined
    clearable
  >
    <template>
      <v-icon
        slot="append"
        v-if="button_aktif"
        @click.stop="button_aktif_callback"
        @mousedown.stop.prevent
        @touchstart.stop.prevent
        color="success"
      >
        mdi-plus
      </v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "Autocomplete",

  data() {
    return {
      model: null,
    };
  },

  props: {
    prependIcon: null,
    value: null,
    items: [],
    label: null,
    itemText: null,
    itemValue: null,
    button_aktif: null,
    button_aktif_callback: null,
    multiple: null,
  },

  methods: {
    onChange(val) {
      this.$emit("changed", val);
    },
  },

  watch: {
    value: function (val) {
      this.model = val;
      this.$forceUpdate();
    },
  },
};
</script>
