<template>
  <div class="text-center">
    <v-dialog persistent v-model="modal" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ cardText }}
        </v-card-title>
        <v-card-text class="mt-5">
          <Autocomplete
            :model="gorev_bilgisi.firma_id"
            :value="gorev_bilgisi.firma_id"
            :items="firmalar"
            label="Firma"
            itemText="unvan"
            itemValue="id"
            
            @changed="(i) => firma_degisti(i)"
          />
          <v-text-field
            v-model="gorev_bilgisi.konu"
            name="konu"
            id="konu"
            label="Konu"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="gorev_bilgisi.aciklama"
            name="aciklama"
            id="aciklama"
            label="Açıklama"
            outlined
          ></v-text-field>
          <Autocomplete
            :model="gorev_bilgisi.atanan_kullanici"
            :value="gorev_bilgisi.atanan_kullanici"
            :items="kullanicilar"
            itemText="adsoyad"
            itemValue="id"
            clearable
            outlined
            label="Görevi Yapacak Kişi"
            @changed="(i) => kullanici_degisti(i)"
          />
          <v-select
            v-model="gorev_bilgisi.aciliyet_durumu"
            :items="durum"
            label="Durum"
            outlined
            @change="(i) => aciliyet_durumu_degisti(i)"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="$emit('hideDialog')">Vazgeç</v-btn>
          <v-btn color="primary" @click="crud"> {{ buttonText }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  gorevBilgileri,
  gorevGuncelle,
  gorevKayit,
} from "../../query/gorevler";
import { firmalarListesi } from "../../query/firmalar";
import { kullanicilarListesi } from "../../query/kullanicilar";
import Autocomplete from "@/components/fields/AutoComplete";

export default {
  watch: {
    modal() {
      this.crudFunction(this.gelen_id);
    },
  },

  props: { modal: null, gelen_id: null },
  data() {
    return {
      gorev_bilgisi: [],
      firmalar: [],
      kullanicilar: [],
      ilceler: [],
      buttonText: "Kaydet",
      cardText: "Yeni Görev",
      crud: this.kaydet,
      durum: ["Normal", "Acil"],
      secilen_aciliyet_durumu: false,
    };
  },

  methods: {
    async crudFunction(gelen_id) {
      console.log("bey", this.gorev_bilgisi.firma_id);
      if (this.gelen_id != null) {
        this.gorev_bilgisi = await gorevBilgileri(gelen_id);
        this.firma_degisti(this.gorev_bilgisi.firma_id);
        this.buttonText = "Güncelle";
        this.cardText = "Bilgileri Güncelle";
        this.crud = this.guncelle;
      } else {
        this.gorev_bilgisi = [];
        this.buttonText = "Kaydet";
        this.cardText = "Yeni Görev";
        this.crud = this.kaydet;
      }
      this.firmalar = await firmalarListesi();
      this.kullanicilar = await kullanicilarListesi();
    },
    async kaydet() {
      const sonuc = await gorevKayit(
        this.gorev_bilgisi.firma_id,
        this.gorev_bilgisi.konu,
        this.gorev_bilgisi.aciklama,
        this.secilen_aciliyet_durumu,
        this.gorev_bilgisi.atanan_kullanici
      );
      if (sonuc !== undefined) {
        this.$emit("hideDialog");
      }
    },
    async guncelle() {
      const sonuc = await gorevGuncelle(
        this.gorev_bilgisi.firma_id,
        this.gorev_bilgisi.konu,
        this.gorev_bilgisi.aciklama,
        this.secilen_aciliyet_durumu,
        this.gorev_bilgisi.atanan_kullanici,
        this.gelen_id
      );
      if (sonuc !== undefined) {
        this.$emit("hideDialog");
      }
    },
    firma_degisti(id) {
      console.log("asaaaaaad");
      this.gorev_bilgisi.firma_id = id;
    },
    kullanici_degisti(id) {
      this.gorev_bilgisi.atanan_kullanici = id;
      console.log("Seçildiiiiii", id);
    },
    aciliyet_durumu_degisti(durum) {
      if (durum == "Normal") {
        this.secilen_aciliyet_durumu = false;
      } else {
        this.secilen_aciliyet_durumu = true;
      }
    },
  },
  components: {
    Autocomplete,
  },
};
</script>
